import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ReadingPage = ({ data }) => (
  <Layout>
    <SEO
      title="Bio"
      keywords={[`gatsby`, `application`, `react`, `portfolio`]}
    />
    <div className="bio-container">
      <p className="bio-heading">Education</p>
      <p className="bio-body">Northwestern University, Evanston IL May 2020</p>
      <p className="bio-body">
        B.A. in Environmental Science + Critical Theory
      </p>
      <br />
      <p className="bio-heading">Interests</p>
      <p className="bio-body">Functional programming</p>
      <p className="bio-body">Historical clothing</p>
      <p className="bio-body">Fermentation</p>
      <p className="bio-body">Mycology</p>
      <p className="bio-body">Electronic music</p>
      <a
        href=" https://en.wikipedia.org/wiki/Masanobu_Fukuoka"
        className="bio-body"
      >
        Natural farming
      </a>
      <br />
      <br />
      <p className="bio-heading">Misc.</p>
      <a
        href="https://vivianxu1230.medium.com/integrating-xlsx-file-uploading-with-a-react-apps-back-end-7a276a6c57ea"
        className="bio-body"
      >
        Blog post on incorporating .csv file uploads to a React app's backend
      </a>
      <br />
      <a href="https://lucky-jewel.com/" className="bio-body">
        Lucky Jewel, a shopping platform I co-founded for artists in Chicago and
        NYC
      </a>
      <br />
      <a
        href="https://sites.northwestern.edu/tol/vivian-xu/"
        className="bio-body"
      >
        2016-2017 Helen G. Scott Prize for Literature and New Media using
        Newhive and a Markov algorithm to digitally remediate Aesop's Fables
      </a>
      <br />
      <a
        href="http://vestoj.com/the-side-hustle-in-your-closet/"
        className="bio-body"
      >
        An essay in Vestoj Journal in which I was interviewed
      </a>

      {/* <h3>Books I'm reading</h3>
    <div className="book-list">
      {data.allBooksJson.edges.map(book => (
        <div key={book.node.id}>
          <p>{book.node.title}</p>
          <p>{book.node.author}</p>
        </div>
      ))}
    </div> */}
    </div>
  </Layout>
)

export default ReadingPage

export const readingQuery = graphql`
  query {
    allBooksJson {
      edges {
        node {
          name
          books {
            title
            author
            date_finished
          }
        }
      }
    }
  }
`
